<template>
  <div class="w-100">
    <div v-if="!$store.state.user" class="w-100 text-white text-center">
      <h4>Are you Pet Owner Click here</h4>

      <div v-if="toggleForm" class="r w-100 mx-auto pt-4">
        <v-form
          ref="formverify"
          @submit.prevent="verifyEmail"
          class="d-flex flex-column align-center justify-cente w-100"
        >
          <v-text-field
            v-model="email"
            label="Email Address"
            :rules="emailRules"
            width="90%"
            variant="solo"
          ></v-text-field>

          <div class="d-flex justify-end">
            <v-btn
              @click.prevent="verifyEmail"
              class="me-4"
              color="#4caf50"
              :loading="loading"
              type="submit"
            >
              Connect
            </v-btn>
          </div>
        </v-form>
      </div>

      <v-btn
        v-else
        @click="toggleForm = true"
        class="my-4"
        color="#4caf50"
        type="submit"
      >
        Click to Edit
      </v-btn>
    </div>

    <div v-if="$store.state.user && $store.state.user.id == pet.owner_info.id">
      <div class="d-flex align-center justify-center">
        <v-chip class="my-4" variant="outlined">
          Signed in as {{ $store.state.user.email }}
        </v-chip>
      </div>

      <div class="d-flex align-center justify-center pb-4">
        <!-- <v-btn @click.prevent="action" class="me-4" color="#4caf50"> -->
        <!-- Edit Your Pet {{ $store.state.user.email }} -->
        <!-- </v-btn> -->
        <v-btn
          width="200px"
          elevation="0"
          @click.prevent="action"
          prepend-icon="mdi-pen"
          color="#4caf50"
        >
          Edit Your Pet
        </v-btn>
      </div>

      <div
        v-if="pet.owner_info.sub.subscription.type === 'basic'"
        class="d-flex align-center justify-center"
      >
        <v-chip class="my-4" variant="outlined" style="color: rgb(81, 110, 5)">
          <v-tooltip activator="parent" location="top"
            >Your Current Plan</v-tooltip
          >
          Basic Plan Expires {{ pet.owner_info.sub.end_date }}
        </v-chip>
      </div>
      <div class="d-flex align-center justify-center">
        <v-btn
          width="200px"
          elevation="0"
          @click="$store.state.openSubscription = true"
          prepend-icon="mdi-update"
          v-if="pet.owner_info.sub.subscription.type !== 'pro'"
        >
          Go Pro
        </v-btn>
        <v-chip
          v-else-if="pet.owner_info.sub.subscription.type === 'pro'"
          class="my-4"
          variant="outlined"
          style="color: rgb(81, 110, 5)"
        >
          Pro Plan Expires {{ pet.owner_info.sub.end_date }}
        </v-chip>
      </div>

      <div class="d-flex align-center justify-center">
        <v-btn
          width="200px"
          elevation="0"
          @click="ManageSubscription"
          prepend-icon="mdi-setting"
          :loading="loading"
        >
          Manage Subscription
        </v-btn>
      </div>

      <div class="d-flex align-center justify-center mt-4">
        <v-btn width="200px" variant="tonal" @click="logOut">
          <v-icon class="pencil">mdi-logout</v-icon>
          Logout
        </v-btn>
      </div>
    </div>
    <div
      class="w-100 text-center"
      v-if="$store.state.user && $store.state.user.id !== pet.owner_info.id"
    >
      <!-- <p class="text-h5 mb-4 text-white">
        You are logged in as {{ $store.state.user.email }}
      </p> -->
      <v-chip class="my-4" variant="outlined">
        You are logged in as {{ $store.state.user.email }}
      </v-chip>
      <p class="text-subtitle-1 mb-4">You are not the owner ⚠️</p>

      <div class="d-flex align-center justify-center">
        <v-btn variant="tonal" width="200px" class="mr-0" @click="logOut">
          <v-icon class="pencil">mdi-logout</v-icon>
          Logout
        </v-btn>
      </div>
    </div>
    <OTPDialog :email="email" @action="action" />
    <EditPetDialogProfile :pet="pet" />
  </div>
</template>

<style lang="scss" scoped>
@import "@/scss/colors";
</style>

<script>
import axiosInstance from "@/utils/axiosInstance";
import OTPDialog from "@/components/OTPDialog.vue";

import { useToast } from "vue-toastification";
import EditPetDialogProfile from "./EditPetDialogProfile.vue";

const toast = useToast();

export default {
  components: { OTPDialog, EditPetDialogProfile },
  data() {
    return {
      toggleForm: false,
      email: "",
      emailRules: [
        (value) => !!value || "Email is required",
        (value) => /.+@.+\..+/.test(value) || "Email must be valid",
      ],
      step: 1,
      openOTP: true,
      timeLeftFree: "",
      loading: false,
    };
  },
  props: ["pet"],

  methods: {
    logOut() {
      this.$store.state.user = null;
      localStorage.removeItem("authkey");
    },
    action() {
      if (this.$store.state.user.id == this.pet.owner_info.id) {
        // this.$store.state.selectedPet = this.pet;
        this.$store.state.selectedPetEdit = JSON.parse(
          JSON.stringify(this.pet)
        );
        this.$store.state.editPet2 = true;
      }
    },

    calculateTimeLeft2() {
      const createdAt = new Date(this.created_at);
      const endTime = new Date(createdAt.getTime() + 30 * 24 * 60 * 60 * 1000);
      const now = new Date();
      const difference = endTime - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        this.timeLeftFree = `${days}d ${hours}h`;
      } else {
        this.timeLeftFree = false;
        clearInterval(this.interval);
      }
    },
    async verifyEmail() {
      const { valid } = await this.$refs.formverify.validate();

      if (!valid) {
        return;
      }

      if (this.pet.owner) {
        if (
          String(this.pet.owner_info.email).trim().toLowerCase() !==
          String(this.email).trim().toLowerCase()
        ) {
          toast.error("Email: Wrong email, you're not the owner of this pet");
          return;
        }
      }
      // print("heree");
      this.loading = true;
      this.$store.state.dashboardLoading = true;
      try {
        const formData = new FormData();
        formData.append("email", this.email);

        await axiosInstance.post(`/lookup-otp/`, formData);
        toast.success(`code sent to ${this.email}`);
        this.$store.state.openotp = true;
      } catch (error) {
        toast.error("You do not have an account with smartPetTag");
      }
      this.loading = false;
      this.$store.state.dashboardLoading = false;
    },

    async ManageSubscription() {
      const successURLPro = `${window.location.href}`;
      const formData = new FormData();

      formData.append("idk", this.$route.params.id);

      formData.append("success", successURLPro);

      try {
        this.loading = true;
        const response = await axiosInstance.post(
          "/direct-to-customer-portal/",
          formData
        );
        if (response.status === 200 && response.data.url) {
          window.location.href = response.data.url;
        } else {
          console.error("Unexpected response:", response);
        }
        this.loading = false;
      } catch (error) {
        console.error("Error creating checkout session:", error);
        this.loading = false;
      }
      // this.loading = false;
    },
  },

  mounted() {
    this.calculateTimeLeft2();

    this.interval2 = setInterval(this.calculateTimeLeft2, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval2);
  },
};
</script>
