<template>
  <div class="wrapper">
    <div class="d-flex justify-space-between pt-8 px-4">
      <v-img class="flex-grow-0" width="160" src="@/assets/smartpet.png" />

      <v-menu width="380" :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn
            icon="mdi-dots-vertical"
            variant="outlined"
            color="teal"
            v-bind="props"
          />
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>
              <v-card width="100%" class="xmy-4">
                <EditPetInProfile :pet="pet"
              /></v-card>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-container class="d-flex flex-column justify-start w-sm-50">
      <div class="d-flex flex-column justify-start pt-6">
        <CountdownTimer
          class="mb-4"
          :pet="pet"
          :created_at="pet.starteduse_at"
        />
        <div>
          <v-card
            variant="outlined"
            color="teal"
            rounded="16px"
            class="mx-auto flex-grow-1 flex-shrink-0 w-full profile-card"
          >
            <v-img
              class="align-end text-white"
              height="400"
              :src="pet.profile_picture"
              cover
            >
            </v-img>

            <v-card-subtitle>
              <div class="my-4 d-flex">
                <span
                  class="text-h6 black--text mr-2 text-weight"
                  style="font-size: 20px; color: #000"
                >
                  Hi, I'm {{ pet.pet_name }}
                </span>
                <v-img
                  class="flex-grow-0 flex-shrink-1"
                  width="30"
                  src="@/assets/emg.png"
                  @click="openEmg = true"
                  v-if="pet.medical_needs"
                />
              </div>
            </v-card-subtitle>

            <v-card-text>
              <v-chip color="grey-darken-3" class="mr-4 text-weight">
                {{ pet.type }}
                <v-tooltip activator="parent" location="top">Type</v-tooltip>
              </v-chip>
              <v-chip color="grey-darken-3" class="mr-4 text-weight">
                {{ pet.gender == "M" ? "Male" : "Female" }}
                <v-tooltip activator="parent" location="top">Gender</v-tooltip>
              </v-chip>
              <v-chip color="grey-darken-3" class="text-weight">
                {{ pet.breed }}
                <v-tooltip activator="parent" location="top">Breed</v-tooltip>
              </v-chip>
            </v-card-text>
          </v-card>
        </div>
      </div>

      <div class="py-4 w-100">
        <div class="text-center">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                color="teal"
                block
                append-icon="mdi-chevron-down"
                class="my-4"
                v-bind="props"
              >
                Contact Owner
              </v-btn>
            </template>

            <v-list bg-color="white">
              <v-list-item>
                <v-list-item-title>
                  <a
                    :href="`tel:${pet.owner_info.phone}`"
                    class="text-decoration-none text-black"
                    >Call Owner</a
                  ></v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="openEmailSend = true">
                <v-list-item-title>
                  <a
                    class="text-decoration-none text-black cursor-pointer"
                    target="_blank"
                    >Email Owner</a
                  ></v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <v-btn
          block
          color="teal"
          variant="outlined"
          prepend-icon="mdi-map-marker"
          v-if="false"
        >
          Share Location
        </v-btn>
      </div>

      <v-card
        elevation="0"
        theme="light"
        color="teal-lighten-5"
        max-width="800"
        class="v-card-profile mt-4"
      >
        <!-- <v-card-title>Contact Info</v-card-title> -->
        <div class="pa-3">
          <div class="d-flex flex-row justify-space-between ma-4">
            <span>
              <v-icon color="teal" icon="mdi-memory" />
              <span class="text-subtitle-2 ml-2">Chipped:</span>
            </span>

            <span class="span">
              {{ pet.microchipped ? pet.chip_id : "None" }}
            </span>
          </div>
          <v-divider />
          <div class="d-flex flex-row justify-space-between ma-4">
            <span>
              <v-icon color="teal" icon="mdi-content-cut" />
              <span class="text-subtitle-2 ml-2">Neutered:</span>
            </span>

            <span class="span"> {{ pet.spay_neutered ? "Yes" : "No" }} </span>
          </div>
          <v-divider />
          <div class="d-flex flex-row justify-space-between ma-4">
            <span>
              <v-icon color="teal" icon="mdi-needle" />
              <span class="text-subtitle-2 ml-2">Vaccination Status:</span>
            </span>

            <span class="span">
              {{ pet.vaccinated_status ? "Yes" : "No" }}
            </span>
          </div>
          <div
            v-if="pet.vaccinated_status"
            class="d-flex flex-row justify-space-between ma-4"
          >
            <span>
              <v-chip-group selected-class="text-primary" column>
                <v-chip
                  v-for="vac in pet.vaccinated_description_info"
                  :key="vac"
                  :text="vac"
                  variant="outlined"
                  filter
                ></v-chip>
              </v-chip-group>
            </span>
          </div>
        </div>
      </v-card>

      <v-card
        elevation="0"
        theme="light"
        max-width="800"
        class="py-2 mt-8 v-card-profile"
      >
        <v-card-title class="text-h5 text-black">Description</v-card-title>
        <v-card-subtitle
          class="display-2 font-weight-regular text-black font-italic"
        >
          "{{ pet.describe }}"
        </v-card-subtitle>
      </v-card>
    </v-container>
    <medicalInfo
      :dialog="openEmg"
      @closeModal="openEmg = false"
      :info="pet.medical_description"
    />

    <SendEmail
      :dialog="openEmailSend"
      @closeModal="openEmailSend = false"
      :pet="pet"
    />
  </div>
</template>

<style scoped>
.profile-card {
  background-color: #e0f2f1 !important;
  border-radius: 16px;
}

.icon-color {
  background: #185a9d;
  color: #ffffff;
}

.text-weight {
  font-weight: 800;
}

.v-card {
  background: none;
}

.span {
  font-size: medium;
  font-weight: 600;
}

.v-list {
  background: none;
}

.badge {
  position: relative;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  background: #185a9d;
  padding: 2px 5px;
  border-radius: 4px;
  top: -2px;
  margin-left: 5px;
}
.wrapper {
  background: #f0f2f5;
  /* background: #ffffff; */

  text-decoration: none;
  /* font-family: bree serif; */
  /* background: -moz-linear-gradient(-45deg, #43cea2, #185a9d); */
  /* FF3.6-15 */
  /* background: -webkit-linear-gradient(-45deg, #43cea2, #185a9d); */
  /* Chrome10-25,Safari5.1-6 */
  /* background: linear-gradient(135deg, #43cea2, #185a9d); */
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /* filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#43cea2', endColorstr='#185a9d', GradientType=1); */
  /* IE6-9 fallback on horizontal gradient */
  min-height: 100svh;
  max-height: 100svh;
  overflow: auto;
  min-height: --webkit-fill-available;
  max-height: --webkit-fill-available;
}

.logo > img {
  width: 160px !important;
}

body {
  min-height: 100svh;
  max-height: 100svh;
  overflow: auto;
  min-height: --webkit-fill-available;
  max-height: --webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

.v-card-profile {
  width: 100%;
}

.image-container {
  position: relative;
  display: inline-block;
}

.main-image {
  display: block;
  border-radius: 12px;
}
</style>

<script>
//
import ProfileCard from "@/components/dashboard/ProfileCard.vue";
import EditPetInProfile from "../dashboard/EditPetInProfile.vue";
import { useToast } from "vue-toastification";
import axiosInstance from "@/utils/axiosInstance";
import medicalInfo from "../medicalInfo.vue";
import SendEmail from "../SendEmail.vue";
import CountdownTimer from "../CountdownTimer.vue";

const toast = useToast();
export default {
  data() {
    return {
      openEmg: false,
      openEmailSend: false,
      panel1: ["one"],
      editPhone: false,
      loadingEdit: false,
      phone: "",
      numberRules: [
        (value) => !!value || "Number is required",
        (value) => !isNaN(value) || "Number must be numeric",
        (value) =>
          (value && value.toString().length >= 10) ||
          "Number must be at least 10 digits long",
      ],
      panel2: ["two"],
    };
  },
  //
  props: ["pet"],
  components: {
    ProfileCard,
    EditPetInProfile,
    medicalInfo,
    SendEmail,
    CountdownTimer,
  },
  methods: {
    async submitForm() {
      const { valid } = await this.$refs.form.validate();

      if (!valid) {
        return;
      }

      this.loadingEdit = true;

      const formData = new FormData();
      formData.append("phone", this.phone);

      try {
        const response = await axiosInstance.patch(
          `/petowners/${this.$store.state.user.id}/`,
          formData
        );
        // console.log("Registration successful:");
        toast.success("Number Updated");
        this.pet.owner_info.phone = this.phone;
        // Handle successful registration (e.g., navigate to another page)
        this.loadingEdit = false;
      } catch (error) {
        // console.error("Registration failed:", error);
        toast.error("Error Updating... try again");
        // Handle registration error (e.g., show a notification)
        this.loadingEdit = false;
      }
    },
  },
  created() {
    this.phone = this.pet.owner_info.phone;
  },
};
</script>
