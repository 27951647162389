// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
// import { VDataTableServer } from "vuetify/labs/VDataTableServer";
import * as components from "vuetify/components";
import * as labsComponents from "vuetify/labs/components";

// Vuetify
import { createVuetify } from "vuetify";

const savedTheme = localStorage.getItem("theme") || "light";

export default createVuetify({
  components: {
    ...components,
    ...labsComponents,
  },
  theme: {
    defaultTheme: savedTheme,
    themes: {
      light: {
        dark: false,
      },
      dark: {
        dark: true,
      },
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
