<template>
  <div>
    <div v-if="dialog" class="modal-overlay" @click="$emit('closeModal')">
      <div class="modal-content" @click.stop>
        <div class="modal-header">
          <h2>Medical Infomation</h2>
          <button class="close-button" @click="$emit('closeModal')">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <p>{{ info }}</p>
        </div>
        <div class="modal-footer">
          <button @click="$emit('closeModal')">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isModalOpen: false,
    };
  },
  methods: {
    openModal() {
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
  },

  props: ["dialog", "info"],

  watch: {
    dialog(value) {
      this.isModalOpen = value;
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1), 0 1px 18px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 95%;
  overflow: hidden;
}

.modal-header {
  background-color: teal;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  padding: 20px;
}

.modal-footer {
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  /* border-top: 1px solid #eee; */
}

button {
  background: teal;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

button:hover {
  background: #1565c0;
}

.close-button {
  background: transparent;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
}
</style>
