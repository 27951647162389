<template>
  <div class="side">
    <div :class="`is-expanded sider`">
      <div class="top">
        <div class="logo-contain">
          <div class="logo">
            <v-img
              :src="require('@/assets/smartpet.png')"
              class="img"
              contain
              style="padding-bottom: 10px; margin-top: 30px"
            />
          </div>
          <!-- <hr style="margin-bottom: 20px" /> -->
        </div>
        <v-divider></v-divider>

        <v-list :lines="two" density="default" nav>
          <v-list-item
            v-for="item in navigationItems"
            :key="item"
            :value="item"
            color="teal"
            class="button"
            :to="`${item.to}/${$store.state.userid}`"
          >
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>

            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";

export default {
  name: "DrawerComp",

  components: {
    Icon,
  },

  data() {
    return {
      opened: [],

      navigationItems: [
        {
          // icon: require("../../assets/home.png"),
          icon: "mdi-face-man",
          // icons: this.icon="solar:home-2-broken",
          title: "My Profile",
          value: "My Profile",
          name: "My Profile",
          link: true,
          divider: false,
          to: "/profile",
          permission: false,
          onlyuser: false,
          both: true,
        },

        {
          // icon: require("../../assets/building.png"),
          icon: "mdi-paw",
          title: "My Pets",
          value: "MyPets",
          name: "MyPets",
          link: true,
          divider: false,
          to: "/pets",
          permission: false,
          onlyuser: true,
          both: false,
        },

        {
          // icon: require("../../assets/setting.png"),
          icon: "mdi-cog",
          title: "Settings",
          value: "settings",
          name: "settings",
          link: true,
          divider: false,
          to: "/settings",
          both: true,
        },
      ],
    };
  },
  props: ["is_expanded"],

  methods: {},
};
</script>

<style lang="scss">
@import "../../scss/_colors";
@import "../../scss/DrawerComp.scss";
</style>
