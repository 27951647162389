<template>
  <router-view />

  <v-overlay
    :model-value="$store.state.dashboardLoading"
    contained
    persistent
    class="align-center justify-center"
    :close-on-content-click="false"
  >
    <v-progress-circular
      color="rgb(81, 110, 5)"
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
// Components

export default {
  name: "App",

  components: {},

  data: () => ({
    //
  }),

  async mounted() {
    await this.$store.dispatch("getUser");
  },

  watch: {
    // Watch for changes in the 'id' route parameter
    "$store.state.error401": function (newId, oldId) {
      // console.log(newId, newId !== undefined, newId === undefined);
      if (newId === true) {
      }
    },
  },
};
</script>

<style>
@import url(https://fonts.googleapis.com/css?family=Poiret+One|Lobster|Anton|Shadows+Into+Light|Open+Sans|Roboto|Orbitron|Oswald|Raleway|Montserrat|Roboto+Slab|Indie+Flower|Fjalla+One|Bree+Serif|Pacifico|Abril+Fatface);
/* @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap"); */
/* html {
  overflow: hidden !important;
} */

.v-stepper-window {
  margin: 0 !important;
}
.iti {
  width: -webkit-fill-available;
  padding: 10px 15px;
  font-size: 16px;
  font-family: "Arial", sans-serif;
  border: 2px solid #ccc;
  border-radius: 25px;
  outline: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  outline: none;
}

.tel:focus {
  outline: none;
  border: none;
}

.tel:focus-visible {
  outline: none;
  border: none;
}

.tel:active {
  outline: none;
  border: none;
}

.v-card {
  --shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.v-stepper-header {
  box-shadow: none !important;
}

.v-stepper {
  box-shadow: none !important;
}

.nice-background {
  background: -moz-linear-gradient(-45deg, #43cea2, #185a9d) !important;
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #43cea2, #185a9d) !important;
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #43cea2, #185a9d) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.responsive-background {
  background: -moz-linear-gradient(-45deg, #43cea2, #185a9d) !important;
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #43cea2, #185a9d) !important;
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #43cea2, #185a9d) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#43cea2', endColorstr='#185a9d', GradientType=1);
  /* background-image: url("@/assets/bg-1.png"); Replace with your image URL */
  /* background-size: cover; Ensure the background image covers the entire container
  background-position: center; Center the image
  background-repeat: no-repeat; Prevent the image from repeating */
  width: 100%;
  height: 100vh; /* Set the height to fill the viewport */
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.variant-elevate {
  box-shadow: rgba(135, 183, 9, 0.1) 0px 54px 55px,
    rgba(135, 183, 9, 0.2) 0px -12px 30px, rgba(135, 183, 9, 0.2) 0px 4px 6px,
    rgba(135, 183, 9, 0.1) 0px 12px 13px, rgba(135, 183, 9, 0.1) 0px -3px 5px !important; /* Box shadow with rgba color for transparency */
}
.not-available {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-items: center;
  align-items: center;
}

.v-theme--light .v-field--active .v-label.v-field-label,
.v-field--focused .v-label.v-field-label {
  opacity: 1;
  color: teal;
}

.v-theme--dark .v-field--active .v-label.v-field-label,
.v-field--focused .v-label.v-field-label {
  opacity: 1;
  color: #a7ffeb;
}

.swal2-show {
  z-index: 2500 !important;
}

.v-skeleton-loader__image {
  height: 100% !important;
}

.v-stepper-item {
  padding: 0.5rem !important;
}

.v-skeleton-loader .v-skeleton-loader__image {
  height: 100% !important;
}

.v-badge__badge {
  display: block !important;
  left: 24px !important;
}
</style>
