import { createStore } from "vuex";

import axiosInstance from "@/utils/axiosInstance";
import Swal from "sweetalert2";
import router from "@/router";

export default createStore({
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    dashboardLoading: false,
    userid: "ddd",
    user: null,
    pet: {},
    addPet: false,
    editPet: false,
    editPet2: false,
    openotp: false,
    opennumber: false,
    openSubscription: false,
    selectedPet: {},
    selectedPetEdit: {},
  },
  mutations: {
    set_user(state, user) {
      state.user = user;
    },
    set_addpet(state, value) {
      state.addPet = value;
    },
    set_subopen(state, value) {
      state.openSubscription = value;
    },
    set_userid(state, userid) {
      state.userid = userid;
    },
    set_loading(state, loading) {
      state.dashboardLoading = loading;
    },

    set_selectedPet(state, data) {
      state.selectedPet = data;
    },
  },

  actions: {
    // get the user instance
    // ...other actions...
    async getUser({ commit }) {
      let user = false;

      commit("set_loading", true);

      try {
        const response = await axiosInstance.get("/user/");
        user = response.data;
        //// console.log(user, "the user");
        if (response.status == 200) {
          commit("set_user", user);
          commit("set_loading", false);
          return true;
        }
        return false;
      } catch (error) {
        commit("set_user", false);
        commit("set_loading", false);
        return false;
      }
      commit("set_loading", false);
    },

    openAddPet({ commit }) {
      if (
        this.state.user.subscription.subscription.type === "free" &&
        this.state.user.pets.length == 1
      ) {
        // console;
        commit("set_subopen", true);
      } else {
        commit("set_addpet", true);
      }
    },

    async getUserLow({ commit }) {
      try {
        let response = await axiosInstance.get("/user/");

        commit("set_user", response.data);
        commit("set_loading", false);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Display a 'Swal' alert for 401 status
          Swal.fire({
            title: "Unauthorized",
            text: "You are not authorized to access this resource.",
            icon: "error",
          }).then(() => {
            // Navigate to the "login" route after clicking "OK"
            router.push("/login");
          });
        } else {
          // Handle other errors if needed
          // console.error("Error fetching users:", error);
        }
      }
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
  },
});
