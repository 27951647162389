<template>
  <pet-profile-default
    :pet="user"
    v-if="user && user.theme === 1"
  ></pet-profile-default>
  <pet-profile-two
    :pet="user"
    v-if="user && user.theme === 2"
  ></pet-profile-two>
  <pet-profile-three
    :pet="user"
    v-if="user && user.theme === 3"
  ></pet-profile-three>
  <pet-profile-four
    :pet="user"
    v-if="user && user.theme === 4"
  ></pet-profile-four>
  <v-overlay
    v-if="!user"
    :model-value="!user"
    contained
    persistent
    class="align-center justify-center"
    :close-on-content-click="false"
  >
    <v-progress-circular
      color="rgb(81, 110, 5)"
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
//
import PetProfileDefault from "@/components/themes/PetProfileDefault.vue";
import axiosInstance from "@/utils/axiosInstance";
import PetProfileTwo from "@/components/themes/PetProfileTwo.vue";
import PetProfileThree from "@/components/themes/PetProfileThree.vue";
import PetProfileFour from "@/components/themes/PetProfileFour.vue";

export default {
  data() {
    return {
      panel1: ["one"],
      panel2: ["two"],
    };
  },
  props: ["user"],
  //
  components: {
    PetProfileDefault,
    PetProfileTwo,
    PetProfileThree,
    PetProfileFour,
  },
  created() {
    // console.log(this.user, "User ehre");
  },
};
</script>
