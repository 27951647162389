<template>
  <v-container>
    <v-card class="px-8 pt-8 pb-10">
      <div>
        <div class="text-h6 font-weight-bold">Subscription Plans</div>
        <div class="subtitle-1 text-grey">Choose a plan to fits your needs</div>
      </div>
      <v-table>
        <thead>
          <tr>
            <th>Features</th>
            <th class="text-center">Free</th>
            <th class="text-center">PRO</th>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr>
            <th class="text-left font-weight-light subtitle-1">
              Number of pet
            </th>
            <td>1</td>
            <td>Unlimited</td>
          </tr>
          <tr>
            <th class="text-left font-weight-light subtitle-1">
              Number of them
            </th>
            <td>2</td>
            <td>4</td>
          </tr>
          <tr>
            <th class="text-left font-weight-light subtitle-1">
              Example Pages
            </th>
            <td>3</td>
            <td>8</td>
          </tr>
          <tr>
            <th class="text-left font-weight-light subtitle-1">
              Login, Register, Pricing, Lock Pages
            </th>
            <td>
              <v-icon color="error"> mdi-close </v-icon>
            </td>
            <td>
              <v-icon color="success"> mdi-check </v-icon>
            </td>
          </tr>
          <tr>
            <th class="text-left font-weight-light subtitle-1">
              Premium Support
            </th>
            <td>
              <v-icon color="error"> mdi-close </v-icon>
            </td>
            <td>
              <v-icon color="success"> mdi-check </v-icon>
            </td>
          </tr>
          <tr>
            <th>Cost</th>
            <td>Free</td>
            <td>Just for <b class="subheading">$79</b></td>
          </tr>
          <tr>
            <th>Choose Plan</th>
            <td>
              <v-btn variant="text"> Current Version </v-btn>
            </td>
            <td>
              <v-btn
                color="success"
                target="_blank"
                href="https://www.creative-tim.com/product/vuetify-material-dashboard-pro?ref=vtymd-upgrade-page"
              >
                Upgrade to Pro
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
  </v-container>
</template>
