<template>
  <div>
    <div v-if="dialog" class="modal-overlay" @click="$emit('closeModal')">
      <div class="modal-content" @click.stop>
        <div class="modal-header">
          <h2>Report Found Pet</h2>
          <button class="close-button" @click="$emit('closeModal')">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="sendEmail">
            <div class="form-group">
              <label for="message"
                >Where You Found the {{ pet.pet_name }}:</label
              >
              <textarea
                id="message"
                v-model="message"
                rows="4"
                required
              ></textarea>
            </div>
            <div class="modal-footer">
              <v-btn :loading="loading" class="mr-2" type="submit">Send</v-btn>
              <button type="button" @click="$emit('closeModal')">Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/utils/axiosInstance";
import { useToast } from "vue-toastification";

const toast = useToast();
export default {
  data() {
    return {
      email: "",
      message: "",
      isModalOpen: false,
      loading: false,
    };
  },
  methods: {
    async sendEmail() {
      this.loading = true;
      // Handle email sending logic here
      // console.log("Email:", this.email);
      // console.log("Message:", this.message);

      try {
        const formData = new FormData();
        formData.append("description", this.message);

        await axiosInstance.post(
          `/report-missing-pet/${this.pet.id}/`,
          formData
        );
        toast.success("Mesaage sent to owner");
      } catch (error) {
        toast.error("Error finding pet");
      }
      // Reset form and close modal
      this.email = "";
      this.message = "";
      this.loading = false;

      this.$emit("closeModal");
    },
    openModal() {
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
  },
  props: ["dialog", "pet"],
  watch: {
    dialog(value) {
      this.isModalOpen = value;
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1), 0 1px 18px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 95%;
  overflow: hidden;
}

.modal-header {
  background-color: teal;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  padding: 20px;
}

.modal-footer {
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
}

button {
  background: teal;
  color: white;
  border: none;
  padding: 6px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

button:hover {
  background: #1565c0;
}

.close-button {
  background: transparent;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 5px;
}

label {
  font-weight: bold;
}
</style>
