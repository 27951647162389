<template>
  <div>
    <v-dialog v-model="$store.state.openotp" persistent max-width="600">
      <v-card
        class="py-8 px-6 text-center mx-auto ma-4 d-flex align-center justify-center"
        elevation="12"
        max-width="400"
        width="100%"
      >
        <h3 class="text-h6 mb-4">Verify Your Account</h3>

        <div class="text-body-2">
          We sent a verification code to {{ email }} <br />

          Please check your email and paste the code below.
        </div>

        <v-sheet color="surface">
          <v-otp-input v-model="otp" length="4" variant="solo"></v-otp-input>
        </v-sheet>

        <v-btn
          :disabled="otp.length < 4 || loading"
          class="my-4"
          color="success"
          height="40"
          text="Verify"
          variant="flat"
          width="70%"
        ></v-btn>

        <div class="text-caption mt-4">
          Didn't receive the code?
          <a href="#" @click.prevent="resendEmail">Resend</a>
        </div>

        <v-btn
          class="text-caption mt-4"
          color="green"
          variant="text"
          @click="closeOtp"
        >
          Used the wrong email?
          <span class="text--primary">Change email</span>
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";

import ProfileCard from "./dashboard/ProfileCard.vue";
import axiosInstance from "@/utils/axiosInstance";

import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  name: "OTPDialog",
  components: { ProfileCard, Icon },
  data() {
    return {
      otp: "",

      loading: false,
      dialog: false,
    };
  },

  props: ["email"],

  methods: {
    closeOtp() {
      this.$store.state.openotp = false;
      this.$emit("clear");
    },
    async resendEmail() {
      this.otp = "";
      this.$store.state.dashboardLoading = true;
      try {
        const formData = new FormData();
        formData.append("email", this.email);
        await axiosInstance.post(`/send-otp/`, formData);
        toast.success(`code sent to ${this.email}`);
        this.$store.state.openotp = true;
      } catch (error) {
        toast.error("error sending code");
      }

      this.$store.state.dashboardLoading = false;
    },
    async verifyOTP() {
      this.loading = true;
      const formData = new FormData();
      formData.append("email", this.email);
      formData.append("otp", this.otp);
      const valid = await axiosInstance.post(`/verify-otp/`, formData);
      const validValue = valid.data.valid;

      if (validValue) {
        localStorage.setItem("authkey", valid.data.access);

        await this.$store.dispatch("getUser");
        this.$emit("action");
        this.otp = "";
        this.$store.state.openotp = false;
        this.loading = false;
      } else {
        toast.error("Invalid or Expired code");
        this.otp = "";
      }
    },
  },

  watch: {
    otp(value) {
      if (this.otp.length === 4) {
        this.verifyOTP();
        // console.log(value);
      }
    },
  },
};
</script>
