<template>
  <div
    style="height: 100svh"
    class="d-flex align-center justify-center py-2 pb-4"
  >
    <v-card max-width="800" class="pa-8">
      <div class="d-flex flex-column">
        <v-img src="../assets/smartpet.png" max-width="300" />
        <p class="text-h6 mt-8 mb-2">Welcome To Smart Pet</p>
        <p class="text-subtitle-1 text-grey mb-4">
          Let us help you keep track of your pets better using the community
        </p>
      </div>

      <v-form @submit.prevent="submitForm">
        <v-text-field
          v-model="name"
          :counter="10"
          :error-messages="v$.name.$errors.map((e) => e.$message)"
          label="Owner's Name"
          @blur="v$.name.$touch"
          @input="v$.name.$touch"
        ></v-text-field>

        <v-text-field
          v-model="email"
          :counter="10"
          :error-messages="v$.email.$errors.map((e) => e.$message)"
          label="Email Address"
          @blur="v$.email.$touch"
          @input="v$.email.$touch"
        ></v-text-field>

        <!-- <v-text-field
          v-model="nickname"
          :counter="10"
          :error-messages="v$.nickname.$errors.map((e) => e.$message)"
          label="Nickname"
          @blur="v$.nickname.$touch"
          @input="v$.nickname.$touch"
        ></v-text-field> -->

        <v-file-input
          :rules="rules"
          v-model="profilePicture"
          accept="image/png, image/jpeg, image/bmp"
          label="Profile Picture"
          placeholder="Pick an avatar"
          prepend-icon="mdi-camera"
        ></v-file-input>

        <!-- <v-text-field
          v-model="houseAddress"
          :counter="10"
          :error-messages="v$.houseAddress.$errors.map((e) => e.$message)"
          label="Address"
        ></v-text-field> -->

        <v-text-field
          v-model="number"
          :counter="10"
          :error-messages="v$.number.$errors.map((e) => e.$message)"
          label="Number"
          @blur="v$.number.$touch"
          @input="v$.number.$touch"
        ></v-text-field>

        <v-btn
          :loading="loading"
          type="submit"
          class="me-4"
          block
          color="success"
          @click.prevent="submitForm"
        >
          Sign Up
        </v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<style scoped>
.fab-bttn {
  position: fixed;
  bottom: 60px;
  right: 20px;
}
</style>

<script>
import { useVuelidate } from "@vuelidate/core";
import { email, numeric, required } from "@vuelidate/validators";
import axiosInstance from "@/utils/axiosInstance";
import { useToast } from "vue-toastification";

const toast = useToast();
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      dialog: false,
      name: "",
      nickname: "",
      email: "",
      houseAddress: "",
      number: "",
      profilePicture: "",
      loading: false,
    };
  },
  validations() {
    return {
      name: { required },
      // nickname: {},
      email: { required, email },
      // houseAddress: { required },
      number: { required, numeric },
    };
  },
  methods: {
    clear() {
      for (const [key, value] of Object.entries(initialState)) {
        state[key] = value;
      }
    },

    async submitForm() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      this.loading = true;

      const formData = new FormData();
      formData.append("name", this.name);
      // formData.append("nickname", this.nickname);
      formData.append("email", this.email);
      formData.append("address", this.houseAddress);
      formData.append("number", this.number);
      formData.append("is_registered", false);
      formData.append("user_code", this.$route.params.id);
      // console.log(this.profilePicture);

      if (this.profilePicture) {
        formData.append("profile_picture", this.profilePicture);
      }

      try {
        const response = await axiosInstance.patch(
          `/petowners/${this.$route.params.id}/`,
          formData
        );
        toast.success("Registration Successfull");
        // console.log("Registration successful:", response.data);
        this.$router.replace(`/profile/${this.$store.state.userid}`);
        // Handle successful registration (e.g., navigate to another page)
        this.loading = false;
      } catch (error) {
        // console.error("Registration failed:", error);
        // Handle registration error (e.g., show a notification)
        this.loading = false;
      }
    },
  },

  async created() {
    // await this.$store.commit("set_userid", this.$route.params.id);
    // await this.$store.dispatch("getUser");
    // // console.log(this.$store.state.userid, this.$store.state.user);
    // if (this.$store.state.user.is_registered) {
    //   this.$router.replace(`/profile/${this.$store.state.userid}`);
    // }
  },
};
</script>
