<template>
  <div
    v-if="
      !pet.paid &&
      $store.state.user &&
      $store.state.user.id === pet.owner_info.id
    "
    class="text-center"
  >
    <p>Free Trial: {{ timeLeft }}</p>
  </div>

  <div
    v-if="
      pet.paid &&
      $store.state.user &&
      $store.state.user.id === pet.owner_info.id &&
      timeLeftFree &&
      pet.owner_info.sub.subscription.type !== 'pro'
    "
    class="text-center"
  >
    <p>Pro Trial: {{ timeLeftFree }}</p>
  </div>
</template>

<script>
export default {
  props: ["created_at", "pet"],
  data() {
    return {
      timeLeft: "",
      timeLeftFree: "",
      interval2: "",
    };
  },
  mounted() {
    this.calculateTimeLeft();
    this.interval = setInterval(this.calculateTimeLeft, 1000);
    this.interval2 = setInterval(this.calculateTimeLeft2, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.interval2);
  },
  methods: {
    calculateTimeLeft2() {
      const createdAt = new Date(this.created_at);
      const endTime = new Date(createdAt.getTime() + 30 * 24 * 60 * 60 * 1000);
      const now = new Date();
      const difference = endTime - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        this.timeLeftFree = `${days}d ${hours}h`;
      } else {
        this.timeLeftFree = false;
        clearInterval(this.interval);
      }
    },

    calculateTimeLeft() {
      const createdAt = new Date(this.created_at);
      const endTime = new Date(createdAt.getTime() + 7 * 24 * 60 * 60 * 1000);
      const now = new Date();
      const difference = endTime - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        this.timeLeft = `${days}d ${hours}h`;
      } else {
        this.timeLeft = "Time is up!";
        clearInterval(this.interval);
      }
    },
  },
};
</script>

<style scoped>
p {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
</style>
