<template>
  <v-card v-bind="$attrs" :class="classes" class="v-card--material pa-3">
    <div if="icon && title" class="ml-4 text-h6 mb-4">
      <p class="text-h6 mb-4">{{ title }}</p>
    </div>
    <div class="d-flex grow flex-wrap">
      <v-avatar v-if="avatar" size="128" class="mx-auto" color="grey">
        <v-img :src="avatar"></v-img>
      </v-avatar>

      <template v-if="$slots.actions">
        <v-divider class="mt-2" />

        <v-card-actions class="pb-0">
          <slot name="actions" />
        </v-card-actions>
      </template>

      <div v-if="$slots['after-heading']" class="ml-6">
        <slot name="after-heading" />
      </div>

      <div v-else-if="icon && title" class="ml-4">
        <div class="card-title font-weight-light" v-text="title" />
      </div>
    </div>

    <slot />

    <template v-if="$slots.actions">
      <v-divider class="mt-2" />

      <v-card-actions class="pb-0">
        <slot name="actions" />
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
export default {
  name: "MaterialCard",

  props: {
    avatar: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "success",
    },
    icon: {
      type: String,
      default: undefined,
    },
    image: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },

  computed: {
    classes() {
      return {
        "v-card--material--has-heading": this.hasHeading,
      };
    },
    hasHeading() {
      return Boolean(this.$slots.heading || this.title || this.icon);
    },
    hasAltHeading() {
      return Boolean(this.$slots.heading || (this.title && this.icon));
    },
  },
};
</script>

<style lang="sass">
.v-card--material
  &__avatar
    position: relative
    top: -64px
    margin-bottom: -32px

  &__heading
    position: relative
    top: -40px
    transition: .3s ease
    z-index: 1
</style>
