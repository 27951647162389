<template>
  <v-dialog v-model="$store.state.editPet" persistent max-width="600">
    <profile-card
      class="v-card-profile"
      :avatar="
        selectedImageUrl
          ? selectedImageUrl
          : this.$store.state.selectedPet.profile_picture
      "
      :title="`Edit ${$store.state.selectedPet.pet_name} Profile`"
    >
      <v-btn
        color="success"
        rounded
        class="mr-0"
        @click="triggerFileInput"
        v-if="!selectedImageUrl"
      >
        <v-icon class="pencil">mdi-pencil</v-icon>
        Change Picture
      </v-btn>
      <v-btn
        v-else
        color="success"
        rounded
        class="mr-0"
        @click="uploadProfilePicture"
        :loading="loading"
      >
        <v-icon class="pencil">mdi-upload</v-icon>
        Upload
      </v-btn>
      <input
        type="file"
        ref="fileInputpet"
        accept="image/*"
        class="d-none"
        @change="handleFileChange"
      />

      <v-btn
        color="success"
        rounded
        class="mr-0 mt-6"
        @click="downloadBarcode($store.state.selectedPet.barcode_image)"
        :loading="loading"
      >
        <v-icon class="pencil">mdi-qrcode</v-icon>
        Download Pet QR COde
      </v-btn>

      <form ref="form" class="mt-4">
        <v-text-field
          v-model="$store.state.selectedPet.pet_name"
          :counter="10"
          label="Pet Name"
          required
        ></v-text-field>

        <v-radio-group v-model="$store.state.selectedPet.gender">
          <template v-slot:label>
            <div>Animal Sex</div>
          </template>
          <v-radio label="Male" value="M"></v-radio>
          <v-radio label="Female" value="F"></v-radio>
        </v-radio-group>

        <v-select
          v-model="$store.state.selectedPet.type"
          :items="items"
          label="Pet Type"
          required
        ></v-select>

        <v-text-field
          v-model="$store.state.selectedPet.breed"
          :counter="10"
          label="Animal Breed"
          required
        ></v-text-field>

        <v-textarea
          v-model="$store.state.selectedPet.medical_description"
          label="Medical Description"
          auto-grow
        ></v-textarea>

        <v-radio-group v-model="$store.state.selectedPet.vaccinated_status">
          <template v-slot:label>
            <div>Vaccination Status</div>
          </template>
          <v-radio label="Vaccinated" :value="true"></v-radio>
          <v-radio label="Not Vaccinated" :value="false"></v-radio>
        </v-radio-group>

        <v-radio-group v-model="$store.state.selectedPet.microchipped" row>
          <template v-slot:label>
            <div>Microchipped</div>
          </template>
          <v-radio label="Yes" :value="true"></v-radio>
          <v-radio label="No" :value="false"></v-radio>
        </v-radio-group>

        <v-radio-group v-model="$store.state.selectedPet.spay_neutered" row>
          <template v-slot:label>
            <div>Spay/Neutered</div>
          </template>
          <v-radio label="Yes" :value="true"></v-radio>
          <v-radio label="No" :value="false"></v-radio>
        </v-radio-group>

        <label>Select Your Pet Theme</label>
        <v-sheet class="mx-auto" max-width="600">
          <v-slide-group
            v-model="selectedTheme"
            ref="slideGroup"
            :center-active="true"
            :show-arrows="false"
          >
            <v-slide-group-item
              v-for="(item, index) in themes"
              :key="item"
              v-slot="{ selectedClass }"
            >
              <v-card height="208" width="100" :class="` ma-4 theme-image `">
                <img
                  @click="
                    () => {
                      if (!lockedTheme(index)) {
                        this.selectedTheme = index + 1;
                      }
                    }
                  "
                  :class="`slide-image ${
                    index + 1 == selectedTheme ? 'selectedClass ' : ''
                  }  ${index + 1 == selectedTheme ? selectedClass : ''}`"
                  :src="item"
                  alt=""
                  width="inherit"
                  style="width: inherit; height: 209px"
                />

                <v-tooltip text="Subcribe to prop to access this theme">
                  <template v-slot:activator="{ props }">
                    <span
                      v-bind="props"
                      style="
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                      "
                      @click="$store.state.openSubscription = true"
                      v-if="lockedTheme(index)"
                    >
                      <Icon
                        v-bind="props"
                        style="font-size: 24px; color: green"
                        icon="mdi-lock"
                      />
                    </span>
                  </template>
                </v-tooltip>
              </v-card>
            </v-slide-group-item>
          </v-slide-group>
        </v-sheet>

        <div class="d-flex justify-end">
          <v-btn
            class="me-4"
            variant="tonal"
            @click="$store.state.editPet = false"
          >
            Cancel</v-btn
          >
          <v-btn
            @click.prevent="submitForm"
            class="me-4"
            color="#4caf50"
            :loading="loading"
          >
            Update Profile
          </v-btn>
        </div>
      </form>
    </profile-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
@import "@/scss/colors";
.fab-bttn {
  position: fixed;
  bottom: 60px;
  right: 20px;
}

theme-image img {
  border-radius: 8px;
  // width: 50%;
}
.selectedClass {
  border: 3px solid $primary-normal;
}

.detail {
  position: absolute;
  transform: translateY(-55px) translateX(10px);
  color: black;
  font-size: 13px;
  font-weight: 500;

  span {
    font-size: 12px !important;
    font-weight: 200;
  }
}
</style>

<script>
import { Icon } from "@iconify/vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import axiosInstance from "@/utils/axiosInstance";

import { useToast } from "vue-toastification";
import ProfileCard from "./dashboard/ProfileCard.vue";

const toast = useToast();

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  components: { ProfileCard, Icon },
  data() {
    return {
      loading: false,
      pet_name: "",
      profile_picture: null,
      gender: "M",
      breed: "",
      medical_description: "",
      vaccinated_status: true,
      microchipped: true,
      spay_neutered: true,
      type: "",
      items: ["Dog", "Cat", "Bird", "Primate", "Rodent", "Snake", "Lizard"],
      newImage: "",
      selectedImageUrl: "",
      selectedTheme: 1,
      themes: [
        require("@/assets/one.png"),
        require("@/assets/two.png"),
        require("@/assets/three.png"),
        require("@/assets/four.png"),
      ],
    };
  },

  methods: {
    lockedTheme(index) {
      return (
        index > 0 &&
        this.$store.state.user.subscription.subscription.type === "free"
      );
    },
    async downloadBarcode(imageUrl) {
      try {
        //// console.log(imageUrl, "imageurl");
        const response = await fetch(`${imageUrl}`);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = `${this.$store.state.selectedPet.pet_name} Barcode`;
        link.click();

        window.URL.revokeObjectURL(url);
      } catch (error) {
        // console.error("Error downloading barcode image:", error);
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.newImage = file;
      if (file) {
        this.selectedImageUrl = URL.createObjectURL(file);
      }
    },

    triggerFileInput() {
      this.$refs.fileInputpet.click();
    },
    async submitForm() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      this.loading = true;

      const formData = new FormData();
      formData.append("pet_name", this.$store.state.selectedPet.pet_name);
      formData.append("gender", this.$store.state.selectedPet.gender);
      formData.append("breed", this.$store.state.selectedPet.breed);
      formData.append(
        "vaccinated_status",
        this.$store.state.selectedPet.vaccinated_status
      );
      formData.append(
        "medical_description",
        this.$store.state.selectedPet.medical_description
      );
      formData.append(
        "spay_neutered",
        this.$store.state.selectedPet.spay_neutered
      );
      formData.append("type", this.$store.state.selectedPet.type);
      formData.append(
        "microchipped",
        this.$store.state.selectedPet.microchipped
      );

      formData.append("user_code", this.$route.params.id);

      try {
        const response = await axiosInstance.patch(
          `/petprofiles/${this.$store.state.selectedPet.id}/`,
          formData
        );
        toast.success(
          `${this.$store.state.selectedPet.pet_name} Profile Updated`
        );

        await this.$store.dispatch("getUserLow");

        // Handle successful registration (e.g., navigate to another page)
        this.loading = false;

        this.$refs.form.reset();
        this.$store.state.addPet = false;
      } catch (error) {
        // console.error("Registration failed:", error);
        // Handle registration error (e.g., show a notification)
        this.loading = false;
      }
      // Form submission logic
      // console.log("Form submitted:", this.state);
    },
    clear() {
      for (const [key, value] of Object.entries(initialState)) {
        state[key] = value;
      }
    },

    async uploadProfilePicture() {
      this.loading = true;

      const formData = new FormData();
      if (this.newImage) {
        formData.append("profile_picture", this.newImage);
      }

      try {
        const response = await axiosInstance.patch(
          `/petprofiles/${this.$route.params.id}/`,
          formData
        );

        toast.success("Picture Updated");

        this.loading = false;
      } catch (error) {
        // console.error("error failed:", error);
        toast.error("error uploading");
        // Handle registration error (e.g., show a notification)
        this.loading = false;
      }
    },
  },
};
</script>
