<template>
  <v-app-bar class="header">
    <div class="header-cover">
      <v-toolbar-title style="display: flex; align-items: center">
        <div class="left-cover">
          <v-btn
            v-if="$store.state.drawerOpened"
            variant="text"
            icon="mdi-chevron-left"
            @click.stop="$store.state.drawerOpened = !$store.state.drawerOpened"
          ></v-btn>
          <v-btn
            v-else
            variant="text"
            icon="mdi-chevron-right"
            @click.stop="$store.state.drawerOpened = !$store.state.drawerOpened"
          ></v-btn>
          <span
            v-if="!$store.state.dashboardLoading"
            class="text-primary-lighten-1"
            >{{ $route.meta.title }}</span
          >
          <v-progress-circular
            v-else
            color="rgb(180, 244, 12)"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-toolbar-title>

      <div class="right-cover">
        <v-btn
          icon
          @click="toggleTheme"
          variant="tonal"
          :color="isDarkTheme ? 'teal-lighten-4' : 'teal'"
        >
          <v-icon>
            {{ isDarkTheme ? "mdi-weather-night" : "mdi-weather-sunny" }}
          </v-icon>
        </v-btn>
      </div>
    </div>
  </v-app-bar>
</template>

<script setup>
import { computed } from "vue";
import { useTheme } from "vuetify";

const theme = useTheme();

const isDarkTheme = computed(() => theme.global.name.value === "dark");

const toggleTheme = () => {
  theme.global.name.value = isDarkTheme.value ? "light" : "dark";
  localStorage.setItem("theme", theme.global.name.value);
};
</script>

<style lang="scss" scoped>
header {
  // box-shadow: none !important;
  .v-toolbar-title {
    padding-left: 20px;
    padding-top: 5px;
    .v-toolbar-title__placeholder {
      display: flex;
      align-items: center;
    }
  }
}

.header .v-toolbar-title {
  padding: 0px !important;
}

.header-cover {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 30px;
  align-items: baseline;
}

.left-cover {
  display: flex;
  align-items: center;
}
.right-cover {
  display: flex;
  align-items: center;
  gap: 20px;
  img {
    // background-color: $primary-light-hover;

    border-radius: 50%;
  }
}

.header .v-toolbar-title {
  font: normal normal medium 24px Montserrat;
  // color: #333333;
  padding-top: 30px;
  /* display: flex; */
  img {
    max-width: 80px !important;
  }
  .page-name {
    font-size: 30px;
    font-weight: 500;
    text-transform: capitalize;

    @media (max-width: 960px) {
      font-size: 21px;
    }
  }
}
.v-toolbar-title__placeholder {
  display: flex;
  align-items: center;
}
</style>
