<template>
  <v-dialog v-model="$store.state.openSubscription" persistent max-width="900">
    <v-card class="px-8 pt-8 pb-10">
      <div class="d-flex justify-space-between">
        <div class="text-h6 font-weight-bold">
          <span>Subscription Plans</span>
          <div class="subtitle-1 text-grey">
            Choose a plan that best fits your needs
          </div>
        </div>

        <div>
          <button
            class="close-button"
            @click="$store.state.openSubscription = false"
            style="font-size: 35px"
          >
            &times;
          </button>
        </div>
      </div>
      <v-table>
        <thead>
          <tr>
            <th>Features</th>
            <th class="text-center">Free Trial</th>
            <th class="text-center">Basic</th>
            <th class="text-center">PRO</th>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr>
            <th class="text-left font-weight-light subtitle-1">
              Number of Pets
            </th>
            <td>1</td>
            <td>3</td>
            <td>10</td>
          </tr>
          <tr>
            <th class="text-left font-weight-light subtitle-1">Pet Themes</th>
            <td>1</td>
            <td>1</td>
            <td>All</td>
          </tr>

          <tr>
            <th class="text-left font-weight-light subtitle-1">
              On Line Support
            </th>
            <td>
              <v-icon color="success"> mdi-check </v-icon>
            </td>
            <td>
              <v-icon color="success"> mdi-check </v-icon>
            </td>
            <td>
              <v-icon color="success"> mdi-check </v-icon>
            </td>
          </tr>

          <tr>
            <th class="text-left font-weight-light subtitle-1">
              Email Notification
            </th>
            <td>
              <v-icon color="error"> mdi-close </v-icon>
            </td>
            <td>
              <v-icon color="success"> mdi-check </v-icon>
            </td>
            <td>
              <v-icon color="success"> mdi-check </v-icon>
            </td>
          </tr>

          <tr>
            <th class="text-left font-weight-light subtitle-1">
              Phone Contact
            </th>
            <td>
              <v-icon color="error"> mdi-close </v-icon>
            </td>
            <td>
              <v-icon color="success"> mdi-check </v-icon>
            </td>
            <td>
              <v-icon color="success"> mdi-check </v-icon>
            </td>
          </tr>

          <tr>
            <th class="text-left font-weight-light subtitle-1">
              SMS Intant Notification
            </th>
            <td>
              <v-icon color="error"> mdi-close </v-icon>
            </td>
            <td>
              <v-icon color="error"> mdi-close </v-icon>
            </td>
            <td>
              <v-icon color="success"> mdi-check </v-icon>
            </td>
          </tr>

          <tr>
            <th>Cost/monthly</th>
            <td>Free - 7 Day Trial</td>
            <td>Just for <b class="subheading">$1.99/mon</b></td>
            <td>
              Just for
              <b class="subheading">$4.99/mon </b>
            </td>
          </tr>
          <tr>
            <th>Cost/yearly</th>
            <td>Free - 7 Day Trial</td>
            <td>Just for <b class="subheading">$23.88/annual + 1 mon</b></td>
            <td>
              Just for
              <b class="subheading">$59.88/annual + 1 mon </b>
            </td>
          </tr>
          <tr>
            <th></th>
            <td></td>
            <td>
              <v-btn
                :loading="loadingB"
                prepend-icon="mdi-arrow-up-bold-box"
                color="success"
                @click.prevent="
                  subscribeAction('basic_monthly', successURLBasic)
                "
                :disabled="
                  $store.state.user.subscription.subscription.type === 'basic'
                "
              >
                Upgrade to Basic
              </v-btn>
            </td>
            <td>
              <v-btn
                :loading="loadingB"
                prepend-icon="mdi-star"
                color="success"
                @click.prevent="subscribeAction('pro_monthly', successURLPro)"
              >
                Upgrade to Pro
              </v-btn>
            </td>
          </tr>

          <tr>
            <th>Choose Plan</th>
            <td>
              <v-btn
                variant="text"
                @click="$store.state.openSubscription = false"
              >
                Go Back
              </v-btn>
            </td>
            <td>
              <v-btn
                :loading="loadingB"
                prepend-icon="mdi-arrow-up-bold-box"
                color="success"
                @click.prevent="
                  subscribeAction('basic_yearly', successURLBasicY)
                "
                :disabled="
                  $store.state.user.subscription.subscription.durationT ===
                  'basicyearly'
                "
              >
                Upgrade to Basic Yearly
              </v-btn>
            </td>
            <td>
              <v-btn
                :loading="loadingB"
                prepend-icon="mdi-star"
                color="success"
                @click.prevent="subscribeAction('pro_yearly', successURLProY)"
              >
                Upgrade to Pro Yearly
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
  </v-dialog>
</template>

<script>
//

import axiosInstance from "@/utils/axiosInstance";
let windowLink =
  window.location.protocol +
  "//" +
  window.location.host +
  window.location.pathname;
export default {
  name: "OTPDialog",
  components: {},
  data() {
    return {
      otp: "",
      pk:
        process.env.VUE_APP_MODE == "development"
          ? String(process.env.VUE_APP_STRIPE_PB_KEY_TEST)
          : String(process.env.VUE_APP_STRIPE_PB_KEY),

      loading: false,
      loadingB: false,
      dialog: false,
      isBasicPlan: false,
      successURLPro: `${windowLink}?session_id={CHECKOUT_SESSION_ID}&type=Pro`,
      successURLBasic: `${windowLink}?session_id={CHECKOUT_SESSION_ID}&type=Basic`,

      successURLProY: `${windowLink}?session_id={CHECKOUT_SESSION_ID}&type=ProYearly`,
      successURLBasicY: `${windowLink}?session_id={CHECKOUT_SESSION_ID}&type=BasicYearly`,
      cancelURL: `${windowLink}`,
      loading: false,

      BasicLineitem: [
        {
          price:
            process.env.VUE_APP_MODE == "development"
              ? "price_1PYVQyCPY6zlYEiWXNRgTOCp"
              : "price_1PYVM2CPY6zlYEiWUv5vqsYk",
          quantity: 1,
        },
      ],

      ProLineitem: [
        {
          price:
            process.env.VUE_APP_MODE == "development"
              ? "price_1PYVRKCPY6zlYEiW1hto4DqY"
              : "price_1PYVMsCPY6zlYEiWtN1RBNgi",
          quantity: 1,
        },
      ],
    };
  },

  methods: {
    proPlan() {
      // You will be redirected to Stripe's secure checkout page
      this.isBasicPlan = false;
      this.loadingB = true;
      // console.log(this.$refs.checkoutRefPro);
      this.$refs.checkoutRefPro.redirectToCheckout();
    },
    async subscribeAction(price_lookup_key, successURL) {
      this.isBasicPlan = true;
      this.loadingB = true;
      const formData = new FormData();
      formData.append("price_lookup_key", price_lookup_key);
      formData.append("idk", this.$route.params.id);
      formData.append("cancel", this.cancelURL);
      formData.append("success", successURL);
      try {
        const response = await axiosInstance.post(
          "/create-checkout-session/",
          formData
        );
        if (response.status === 200 && response.data.url) {
          window.location.href = response.data.url;
        } else {
          console.error("Unexpected response:", response);
        }
      } catch (error) {
        console.error("Error creating checkout session:", error);
      }
    },
  },
};
</script>
