export const dogBreeds = [
  "Afghan Hound",
  "Airedale Terrier",
  "Akita",
  "Alaskan Malamute",
  "Australian Cattle Dog",
  "Australian Shepherd",
  "Basenji",
  "Basset Hound",
  "Beagle",
  "Belgian Malinois",
  "Bernese Mountain Dog",
  "Bichon Frise",
  "Border Collie",
  "Border Terrier",
  "Boston Terrier",
  "Boxer",
  "Brittany",
  "Bulldog",
  "Bullmastiff",
  "Cairn Terrier",
  "Cavalier King Charles Spaniel",
  "Chesapeake Bay Retriever",
  "Chihuahua",
  "Chinese Crested",
  "Chow Chow",
  "Cocker Spaniel",
  "Collie",
  "Dachshund",
  "Dalmatian",
  "Doberman Pinscher",
  "English Springer Spaniel",
  "French Bulldog",
  "German Shepherd",
  "German Shorthaired Pointer",
  "Golden Retriever",
  "Great Dane",
  "Great Pyrenees",
  "Havanese",
  "Irish Setter",
  "Italian Greyhound",
  "Jack Russell Terrier",
  "Labrador Retriever",
  "Maltese",
  "Miniature Schnauzer",
  "Pomeranian",
  "Poodle",
  "Pug",
  "Rottweiler",
  "Shih Tzu",
  "Siberian Husky",
];

export const catBreeds = [
  "Abyssinian",
  "Aegean",
  "American Bobtail",
  "American Curl",
  "American Ringtail",
  "American Shorthair",
  "American Wirehair",
  "Arabian Mau",
  "Asian",
  "Asian Semi-longhair",
  "Australian Mist",
  "Balinese",
  "Bambino",
  "Bengal",
  "Birman",
  "Bombay",
  "Brazilian Shorthair",
  "British Longhair",
  "British Shorthair",
  "Burmese",
  "Burmilla",
  "California Spangled",
  "Chantilly-Tiffany",
  "Chartreux",
  "Chausie",
  "Cheetoh",
  "Colorpoint Shorthair",
  "Cornish Rex",
  "Cymric",
  "Cyprus",
  "Devon Rex",
  "Donskoy",
  "Dragon Li",
  "Dwelf",
  "Egyptian Mau",
  "European Shorthair",
  "Exotic Shorthair",
  "Foldex",
  "German Rex",
  "Havana Brown",
  "Highlander",
  "Himalayan",
  "Japanese Bobtail",
  "Javanese",
  "Khao Manee",
  "Korat",
  "Korean Bobtail",
  "Kurilian Bobtail",
  "LaPerm",
  "Lykoi",
  "Maine Coon",
  "Manx",
  "Minskin",
  "Munchkin",
  "Napoleon",
  "Nebelung",
  "Norwegian Forest Cat",
  "Ocicat",
  "Ojos Azules",
  "Oriental Bicolor",
  "Oriental Longhair",
  "Oriental Shorthair",
  "Persian",
  "Peterbald",
  "Pixie-bob",
  "Ragamuffin",
  "Ragdoll",
  "Russian Blue",
  "Russian White",
  "Sam Sawet",
  "Savannah",
  "Scottish Fold",
  "Selkirk Rex",
  "Serengeti",
  "Serrade Petit",
  "Siamese",
  "Siberian",
  "Singapura",
  "Snowshoe",
  "Sokoke",
  "Somali",
  "Sphynx",
  "Suphalak",
  "Thai",
  "Thai Lilac",
  "Tonkinese",
  "Toyger",
  "Turkish Angora",
  "Turkish Van",
  "Ukrainian Levkoy",
  "Ural Rex",
  "York Chocolate",
  "Sokoke",
  "Bombay",
  "Japanese Bobtail",
  "Chantilly-Tiffany",
  "Highlander",
  "Asian Semi-longhair",
  "Arabian Mau",
  "Toyger",
];
