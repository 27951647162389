<template>
  <v-dialog v-model="$store.state.editPet2" persistent max-width="600">
    <profile-card
      class="v-card-profile"
      :avatar="
        selectedImageUrl
          ? selectedImageUrl
          : this.$store.state.selectedPetEdit.profile_picture
      "
      :title="`Edit ${$store.state.selectedPet.pet_name}'s Profile`"
    >
      <v-btn
        color="success"
        rounded
        class="mr-0"
        @click="triggerFileInput"
        v-if="!selectedImageUrl"
      >
        <v-icon class="pencil">mdi-pencil</v-icon>
        Change Picture
      </v-btn>
      <v-btn
        v-else
        color="success"
        rounded
        class="mr-0"
        @click="uploadProfilePicture"
        :loading="loading"
      >
        <v-icon class="pencil">mdi-upload</v-icon>
        Upload
      </v-btn>
      <input
        type="file"
        ref="fileInputpet"
        accept="image/*"
        class="d-none"
        @change="handleFileChange"
      />

      <form ref="form" class="mt-4">
        <v-text-field
          v-model="$store.state.selectedPetEdit.pet_name"
          :counter="10"
          label="Pet Name"
          required
        ></v-text-field>

        <v-textarea
          v-model="$store.state.selectedPetEdit.describe"
          label="Describe Your Pet"
          auto-grow
        ></v-textarea>

        <v-radio-group v-model="$store.state.selectedPetEdit.gender">
          <template v-slot:label>
            <div>Animal Sex</div>
          </template>
          <v-radio label="Male" value="M"></v-radio>
          <v-radio label="Female" value="F"></v-radio>
        </v-radio-group>

        <v-select
          v-model="$store.state.selectedPetEdit.type"
          :items="items"
          label="Pet Type"
          required
        ></v-select>

        <v-autocomplete
          label="Dog Breed"
          :items="dogBreeds.concat(newBreed)"
          v-model:search="search"
          v-model="$store.state.selectedPetEdit.breed"
          v-if="$store.state.selectedPetEdit.type == 'Dog'"
          :filter-option="filterOption"
        ></v-autocomplete>

        <v-autocomplete
          label="Cat Breed"
          :items="catBreeds.concat(newBreed)"
          v-model:search="search"
          v-model="$store.state.selectedPetEdit.breed"
          v-else-if="$store.state.selectedPetEdit.type == 'Cat'"
        ></v-autocomplete>

        <v-text-field
          v-else
          v-model="$store.state.selectedPetEdit.breed"
          :counter="10"
          label="Animal Breed"
          required
        ></v-text-field>

        <div class="my-6">
          <v-switch
            v-model="$store.state.selectedPetEdit.medical_needs"
            label="My Pet Has Medical Emergencies"
            color="teal"
          ></v-switch>

          <v-textarea
            v-if="$store.state.selectedPetEdit.medical_needs"
            v-model="$store.state.selectedPetEdit.medical_description"
            label="Medical Description"
            auto-grow
          ></v-textarea>
        </div>

        <div class="my-6">
          <v-switch
            v-model="$store.state.selectedPetEdit.vaccinated_status"
            label="My Pet Is Vaccinated"
            color="teal"
          ></v-switch>

          <v-card
            v-if="$store.state.selectedPetEdit.vaccinated_status"
            elevation="0"
          >
            <p class="text-h6">Select All That Apply</p>
            <v-chip-group
              selected-class="text-primary"
              multiple
              column
              v-model="$store.state.selectedPetEdit.vaccinated_description_info"
            >
              <v-chip
                v-for="vacin in vaccination"
                :key="vacin"
                :value="vacin"
                :text="vacin"
                variant="outlined"
                filter
              ></v-chip>
            </v-chip-group>
          </v-card>
        </div>

        <div class="my-6">
          <v-switch
            v-model="$store.state.selectedPetEdit.microchipped"
            label="Microchipped"
            color="teal"
          ></v-switch>

          <v-text-field
            v-if="$store.state.selectedPetEdit.microchipped"
            :counter="15"
            max="15"
            label="Chip Number"
            v-model="$store.state.selectedPetEdit.chip_id"
          ></v-text-field>
        </div>

        <v-switch
          v-model="$store.state.selectedPetEdit.spay_neutered"
          label="Spay/Neutered"
          color="teal"
        ></v-switch>

        <label>Select Your Pet Theme</label>
        <v-sheet class="mx-auto" max-width="600">
          <v-slide-group
            v-model="$store.state.selectedPetEdit.theme"
            ref="slideGroup"
            :center-active="true"
            :show-arrows="false"
          >
            <v-slide-group-item
              v-for="(item, index) in themes"
              :key="item"
              v-slot="{ selectedClass }"
            >
              <v-card height="208" width="100" :class="` ma-4 theme-image `">
                <img
                  @click="
                    () => {
                      if (!lockedTheme(index)) {
                        this.$store.state.selectedPetEdit.theme = index + 1;
                      }
                    }
                  "
                  :class="`slide-image ${
                    index + 1 == $store.state.selectedPetEdit.theme
                      ? 'selectedClass '
                      : ''
                  }  ${
                    index + 1 == $store.state.selectedPetEdit.theme
                      ? selectedClass
                      : ''
                  }`"
                  :src="item"
                  alt=""
                  width="inherit"
                  style="width: inherit; height: 209px"
                />

                <span
                  style="
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  "
                  v-if="lockedTheme(index)"
                >
                  <v-tooltip activator="parent" location="top"
                    >Subcribe to Pro Plan to access this theme</v-tooltip
                  >
                  <Icon
                    v-bind="props"
                    style="font-size: 24px; color: green"
                    icon="mdi-lock"
                    @click="$store.state.openSubscription = true"
                  />
                </span>
              </v-card>
            </v-slide-group-item>
          </v-slide-group>
        </v-sheet>

        <div class="d-flex justify-end">
          <v-btn
            class="me-4"
            variant="tonal"
            @click="$store.state.editPet2 = false"
          >
            CANCEL</v-btn
          >
          <v-btn
            @click.prevent="submitForm"
            class="me-4"
            color="#4caf50"
            :loading="loading"
          >
            Update Profile
          </v-btn>
        </div>
      </form>
    </profile-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
@import "@/scss/colors";
.fab-bttn {
  position: fixed;
  bottom: 60px;
  right: 20px;
}

theme-image img {
  border-radius: 8px;
  // width: 50%;
}
.selectedClass {
  border: 3px solid $primary-normal;
}

.detail {
  position: absolute;
  transform: translateY(-55px) translateX(10px);
  color: black;
  font-size: 13px;
  font-weight: 500;

  span {
    font-size: 12px !important;
    font-weight: 200;
  }
}
</style>

<script>
import { Icon } from "@iconify/vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import axiosInstance from "@/utils/axiosInstance";

import { dogBreeds, catBreeds } from "@/utils/breeds";

import { useToast } from "vue-toastification";
import ProfileCard from "./ProfileCard.vue";

const toast = useToast();

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  components: { ProfileCard, Icon },
  data() {
    return {
      vaccination: [
        "Rabies",
        "Leptospirosis",
        "Canine distemper",
        "Canine influenza.",
        "Feline panleukopenia",
      ],
      loading: false,
      pet_name: "",
      profile_picture: null,
      gender: "M",
      breed: "",
      medical_description: "",
      vaccinated_status: true,
      microchipped: true,
      spay_neutered: true,
      type: "",
      items: ["Dog", "Cat", "Bird", "Primate", "Rodent", "Snake", "Lizard"],
      newImage: "",
      selectedImageUrl: "",
      selectedTheme: 1,
      themes: [
        require("@/assets/one.png"),
        require("@/assets/two.png"),
        require("@/assets/three.png"),
        require("@/assets/four.png"),
      ],

      search: "",
      dogBreeds: dogBreeds,

      catBreeds: catBreeds,

      newBreed: [],
    };
  },

  props: ["pet"],

  methods: {
    lockedTheme(index) {
      if (!this.pet.paid) {
        if (index > 0) {
          return true;
        }
        return false;
      }
      if (
        this.calculateTimeLeft2(this.pet.starteduse_at) &&
        this.$store.state.selectedPetEdit.owner_info.sub.subscription.type !==
          "Pro"
      ) {
        return false;
      }
      return (
        index > 0 &&
        this.$store.state.selectedPetEdit.owner_info.sub.subscription.type !==
          "Pro"
      );
    },

    calculateTimeLeft2(created_at) {
      const createdAt = new Date(created_at);
      const endTime = new Date(createdAt.getTime() + 30 * 24 * 60 * 60 * 1000);
      const now = new Date();
      const difference = endTime - now;

      if (difference > 0) {
        return true;
      } else {
        this.timeLeftFree = false;
        return false;
      }
    },
    async downloadBarcode(imageUrl) {
      try {
        //// console.log(imageUrl, "imageurl");
        const response = await fetch(`${imageUrl}`);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = `${this.$store.state.selectedPetEdit.pet_name} Barcode`;
        link.click();

        window.URL.revokeObjectURL(url);
      } catch (error) {
        // console.error("Error downloading barcode image:", error);
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.newImage = file;
      if (file) {
        this.selectedImageUrl = URL.createObjectURL(file);
      }
    },

    triggerFileInput() {
      this.$refs.fileInputpet.click();
    },
    async submitForm() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      const fields = [
        "pet_name",
        "gender",
        "breed",
        "medical_needs",

        "vaccinated_status",
        "theme",

        "describe",
        "spay_neutered",
        "type",
        "microchipped",
      ];

      for (let field of fields) {
        const value = this.$store.state.selectedPetEdit[field];
        if (
          value === null ||
          value === undefined ||
          value === "" ||
          (Array.isArray(value) && value.length === 0)
        ) {
          toast.error(`The field ${field.replace("_", " ")} cannot be empty`);
          this.loading = false;
          return;
        }
      }

      if (
        this.$store.state.selectedPetEdit.vaccinated_status &&
        this.$store.state.selectedPetEdit.vaccinated_description_info.length < 1
      ) {
        toast.error(`Select at least one vaccination type`);
        return;
      }

      if (
        this.$store.state.selectedPetEdit.microchipped &&
        this.$store.state.selectedPetEdit.chip_id.replace(/\s+/g, "") === ""
      ) {
        toast.error(`Input your Microchip Id`);
        return;
      }

      this.loading = true;

      const formData = new FormData();
      formData.append("pet_name", this.$store.state.selectedPetEdit.pet_name);
      formData.append("gender", this.$store.state.selectedPetEdit.gender);
      formData.append("breed", this.$store.state.selectedPetEdit.breed);
      formData.append(
        "medical_needs",
        this.$store.state.selectedPetEdit.medical_needs
      );
      formData.append(
        "medical_description",
        this.$store.state.selectedPetEdit.medical_description
      );
      formData.append(
        "vaccinated_status",
        this.$store.state.selectedPetEdit.vaccinated_status
      );
      formData.append("theme", this.$store.state.selectedPetEdit.theme);

      formData.append(
        "vaccinated_description",
        this.$store.state.selectedPetEdit.vaccinated_description_info.join(", ")
      );
      formData.append("describe", this.$store.state.selectedPetEdit.describe);

      formData.append(
        "spay_neutered",
        this.$store.state.selectedPetEdit.spay_neutered
      );
      formData.append("type", this.$store.state.selectedPetEdit.type);
      formData.append(
        "microchipped",
        this.$store.state.selectedPetEdit.microchipped
      );

      formData.append("chip_id", this.$store.state.selectedPetEdit.chip_id);

      try {
        const response = await axiosInstance.patch(
          `/petprofiles/${this.$store.state.selectedPetEdit.id}/`,
          formData
        );

        this.$store.state.pet = response.data;
        this.$store.commit("set_selectedPet", response.data);

        toast.success(
          `${this.$store.state.selectedPetEdit.pet_name} Profile Updated`
        );

        // await this.$store.dispatch("getUserLow");

        // Handle successful registration (e.g., navigate to another page)
        this.loading = false;

        this.$store.state.addPet = false;
      } catch (error) {
        // console.error("Registration failed:", error);
        // Handle registration error (e.g., show a notification)
        this.loading = false;
      }
      // Form submission logic
      // console.log("Form submitted:", this.state);
    },
    clear() {
      for (const [key, value] of Object.entries(initialState)) {
        state[key] = value;
      }
    },

    async uploadProfilePicture() {
      this.loading = true;

      const formData = new FormData();
      if (this.newImage) {
        formData.append("profile_picture", this.newImage);
      }

      try {
        const response = await axiosInstance.patch(
          `/petprofiles/${this.$route.params.id}/`,
          formData
        );

        toast.success("Picture Updated");

        this.loading = false;
      } catch (error) {
        // console.error("error failed:", error);
        toast.error("error uploading");
        // Handle registration error (e.g., show a notification)
        this.loading = false;
      }
    },
  },

  watch: {
    search(newData, olddata) {
      // console.log(newData, olddata, "search");
      this.newBreed = [newData];
    },
  },
};
</script>
