import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import MobileLayout from "@/views/Layout/MobileLayout.vue";

import authLayout from "@/views/Layout/authLayout.vue";
import PetProfile from "../views/PetProfile.vue";

import Subscription from "../views/Layout/Subscription.vue";
import NewUser from "../views/NewUser.vue";
import NotFound from "../views/NotFound.vue";
import NotValid from "../views/NotValid.vue";

let dashboardView = {
  path: "/dash",
  component: DashboardLayout,
  name: "app",
  redirect: "/app/profile",

  children: [
    {
      path: "/app/profile",
      name: "dashboard",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "../views/components/OverView.vue"
        ),
      meta: { title: "My Profile", requiresAuth: true },
      // beforeEnter: checkAuthentication,
    },

    // {
    //   path: "/pets/:id",
    //   name: "My Pets",
    //   component: () =>
    //     import(/* webpackChunkName: "home" */ "../views/MyPet.vue"),
    //   meta: { title: "My Pets", requiresAuth: true },
    //   // beforeEnter: checkAuthentication,
    // },

    {
      path: "/settings/:id",
      name: "settings",
      component: () =>
        import(/* webpackChunkName: "home" */ "../views/Settings.vue"),
      meta: { title: "Settings", requiresAuth: true },
      // beforeEnter: checkAuthentication,
    },
  ],
};

let mobileView = {
  path: "/",
  component: MobileLayout,
  name: "app",
  redirect: "/pet",

  children: [
    {
      path: "/pet/:id",
      name: "pet Profile",
      component: () =>
        import(/* webpackChunkName: "home" */ "../views/OverView.vue"),
      meta: { title: "Profile", requiresAuth: true },
      // beforeEnter: checkAuthentication,
    },
  ],
};

let authView = {
  path: "/auth",
  component: authLayout,
  name: "auth",
  children: [],
};

let profileView = {
  path: "/pet-info/:petid/:id?",
  component: PetProfile,
  name: "My Pet",
  children: [],
};

let subscriptionView = {
  path: "/subscribe",
  component: Subscription,
  name: "Available Plans",
  children: [],
};

let welcomeView = {
  path: "/signup",
  component: NewUser,
  name: "Sign Up",
};

let notValid = {
  path: "/notvalid",
  component: NotValid,
  name: "Invalid Qr code",
};

let notfound = {
  path: "/:pathMatch(.*)*",
  component: NotFound,
  name: "Not Found",
  children: [],
};

const routes = [
  dashboardView,
  authView,
  profileView,
  subscriptionView,
  welcomeView,
  notfound,
  notValid,
  mobileView,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
