<template>
  <div>
    <router-view></router-view>
    <SubscriptionModal />
  </div>
</template>

<script>
// import OnBoarding from "../mobile/OnBoarding.vue";

const userAgent = navigator.userAgent.toLowerCase();
const isAndroid = /android/i.test(userAgent);
const isIos = /iphone|ipad|ipod/.test(userAgent);

import SubscriptionModal from "@/components/SubscriptionModal.vue";
export default {
  name: "MobileLayout",
  data: () => ({
    installEvent: null,
    isIPhone: false,
    isIPhone: isIos,
    isAndroid: isAndroid,
  }),

  methods: {},

  components: { SubscriptionModal },

  async created() {
    // await this.$store.dispatch("getUser");
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "../../scss/colors";
.v-main {
  font-family: Inter, Verdana, sans-serif;
  /* background-color: #1c1c1e; */
  // background-color: $neutral-dark;
}

.logo {
  position: absolute;
  color: white;
  font-size: 20px;
  z-index: 1;
  top: 0;
  left: 0;
  width: 80px;
}

.not-allowed {
  color: $secondary-darker;
  background-color: $neutral-dark-hover;
  height: 100vh;
  display: flex;
  align-content: center;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-size: 60px;
    /* font-style: italic; */
  }

  h2 {
    font-size: 40px;
    font-style: italic;
    color: $primary-normal-active;
  }
}
</style>
